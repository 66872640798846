import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";


import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";


class RowItem extends Component {

    constructor(props) {
        super(props);

    }

    state = {
        open: false,
        unidades: null,
    };

    async componentDidMount() {
        try {
            const responseLotes = await fetch('https://rvgdg1p2a1.execute-api.us-east-2.amazonaws.com/Staging/api/product/landing/' + this.props.entrepreneurship[0].id + '?ambiences=' + this.props.ambiences);
            const lotes = await responseLotes.json();
            this.setState({ unidades: lotes });

            if (!responseLotes.ok) {
                console.error(responseLotes);
            }

        } catch (error) {
            console.log(error);
        }
    }

    toggleTab() {
        this.setState({ open: !this.state.open });
    }

    render() {
        return (
            <>
                <div className="lineaTabla" style={{ borderBottom: this.state.open ? '1px solid black' : '', borderBottom: this.props.ambiences == 3 ? '1px solid black' : '' }}>
                    <div className="tablaUnidadesCol1">{this.props.title}</div>
                    {!this.state.open ?
                        <>
                            <BrowserView>
                                <div><a href="#" className="btn btn-custom btnn-tabla contactooo sLinea">Contactar</a></div>
                            </BrowserView>
                            <MobileView>
                                <div><a href="#" className="btn btn-primary btn-custom btn-table-unidades contactooo">Contactar</a></div>
                            </MobileView>
                        </>
                        :
                        <div></div>
                    }
                    {/*<div><a href="#" className="btn btn-primary btn-custom btn-tabla">Reservar</a></div>*/}
                    <div className="btnCollapseTabla">
                        {!this.state.open ?
                            <BsChevronDown onClick={() => this.toggleTab()} />
                            :
                            <BsChevronUp onClick={() => this.toggleTab()} />
                        }
                    </div>
                </div>

                {this.state.open && this.state.unidades != null ?
                    <div className="boxUnidades scrollBarLotes">
                        {
                            this.state.unidades.map(function (item, i) {
                                return (
                                    <>
                                        <BrowserView>
                                            <div className="lineaTablaLotesRow" key={i}>
                                                <div className="" style={{ color: '#7ee0a6' }}>{item.text1}</div> {/*numero unidad*/}
                                                <div className="">{item.text2}</div>{/*60m2*/}
                                                <div className="">{item.text3}</div>{/*bano*/}
                                                <div className="">{item.text4}</div>{/*orientacion*/}
                                                <div className="">{item.text5}</div>{/*importe*/}
                                                <div className="">{item.text3}</div>{/*bano*/}
                                                <div><a href={item.pictureurl ? item.pictureurl : '#'} className="contactooo">Ver Plano</a></div>
                                                <div><a href="#contacto" className="btn btnn-tabla btn-custom-black">Contactar</a></div>
                                                {/*                                                 <div><a href={item.bookingurl ? item.bookingurl : ''} className="btn btn-custom-black buttonBookTable">Reservar</a></div>
 */}                                            </div>
                                        </BrowserView>
                                        <MobileView>
                                            <div className="lineaTablaLotesRow" key={i}>
                                                <div className="" style={{ color: '#7ee0a6' }}>{item.text1}</div>{/*2a*/}
                                                {/*<div className="">{item.text2}</div>{/*60m2*/}
                                                {/*<div className="">{item.text4}Sur</div>{/*sur*/}
                                                {/*<div className=""></div>{/*vacio*/}
                                                {/*<div className="">{item.text3}2 baños</div>{/*bano*/}
                                                <div className="">{item.text5}</div>{/*100000*/}
                                                {/*<div className=""></div>{/*vacio*/}
                                                {/*<div style={{marginTop: '23px', marginBottom: '20px'}}><a href={item.pictureurl ? item.pictureurl : ''} className="contactooo">Ver Plano</a></div>*/}
                                                <div><a href="#contacto" className=" btn btn-primary btn-custom-black btn-table-unidades-inverse contactooo">Contactar</a></div>
                                            </div>
                                        </MobileView>
                                    </>
                                )
                            })
                        }
                    </div >
                    :
                    null
                }
            </>
        );
    }
}

export default RowItem;
