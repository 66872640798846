import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import wp from "../../assets/images/logo_whatsapp.svg";
import ig from "../../assets/images/ig.png";
import fb from "../../assets/images/fb.png";


class Section extends Component {
    constructor(props) {
        super(props);


        this.state = {
            innerHeight: window.innerHeight,

        };

    }


    render() {
        return (
            <React.Fragment>
                <section className="bg-home" id="home" style={{ height: this.state.innerHeight }}>
                    <div className="home-center">

                        <div className={'squareBottom '}>
                            <Col style={{ marginTop: '12px', marginLeft: '22%' }}><a href={'https://api.whatsapp.com/send?phone=' + this.props.numContacto + '&source=&data=&app_absent='}><img src={wp} height="27" style={{ marginRight: '17px', float: 'left' }} /></a></Col>
                            <Col><img src={ig} height="27" style={{ marginRight: '17px', float: 'left' }} /></Col>
                        </div>
                        <div className="home-desc-center">
                            <Container className="container1298px">
                                <Row className="row justify-content-left">
                                    <Col lg="12" className="text-left paddMargen">
                                        <h1 className="home-title text-white">Viví 365</h1>
                                        <h1 className="home-title text-white">Disfrutá 475</h1>
                                        <p className="home-sub-title text-white" style={{ marginBottom: '50px' }}>Un nuevo modo de vivir la ciudad. Para que las mejores experiencias encuentren lugar.</p>
                                        <a href="#unidades" className="btn btn-custom" style={{fontSize: '15px'}}>Ver unidades</a>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </section>
            </React.Fragment >
        );
    }
}

export default Section;