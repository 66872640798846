import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import ScrollspyNav from "./scrollSpy";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
//Import Images
import logolight from "../../assets/images/logo_header.svg";
import logodark from "../../assets/images/logo_header.svg";
import contactoo from "../../assets/images/icono_mail.svg";


class NavbarPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: "proyecto", navheading: "Proyecto" },
        { id: 2, idnm: "unidades", navheading: "Unidades" },
        { id: 3, idnm: "ubicacion", navheading: "Ubicación" },

      ],



      isOpen: false,
      navclassDeff: ''
    };
    this.toggleLine = this.toggleLine.bind(this);
  }

  toggleLine() {


    this.setState(
      (prevState) => (
        { isOpen: !prevState.isOpen }
      ),
      () => {


        if (this.state.isOpen) {
          this.setState({ navclassDeff: 'darkheader' });

        } else {
          this.setState({ navclassDeff: '' });

        }
      }
    );


  }
  render() {
    //Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
    let targetId = this.state.navItems.map((item) => {
      return item.idnm;
    });

    return (

      this.props.navclass != 'darkheader' ?
        <React.Fragment>
          <header id="topnav" className={this.props.isNotSticky === true ? "defaultscroll sticky " + this.props.navclass + this.state.navclassDeff : "defaultscroll fixed-top sticky " + this.props.navclass + this.state.navclassDeff}>
            <Container className="containerFull">
              <Row className="alturaMenu">
                <Col
                  style={{ backgroundColor: 'transparent' }}
                  className={'hideDesktop showMobile'}
                >
                  <div className={'posMargen'}>
                    <Link to="/" className="logo text-uppercase"> <img src={logolight} alt="" id="logotipo" className="logo-light logotipo" height="40" /></Link>
                  </div>
                </Col>

                <Col
                  className={'fondo despegado logoReubicacion'}
                  xs={3}
                  lg={6}

                >
                  <div className={'posMargen hideMobile'}>
                    <Link to="/" className="logo text-uppercase"> <img src={logolight} alt="" id="logotipo" className="logo-light logotipo" height="40" /></Link>
                  </div>
                  <div className="menu-extras">
                    <div className="menu-item">
                      <Link to="#" onClick={this.toggleLine} className={this.state.isOpen ? "navbar-toggle open" : "navbar-toggle"} >
                        <div className="lines">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <ScrollspyNav
                    scrollTargetIds={targetId}
                    scrollDuration="300"
                    headerBackground="false"
                    activeNavClass="active"
                  >
                    {/*menu mobile show*/}
                    <div id="navigation "
                      style={{ display: this.state.isOpen ? "block" : "", marginLeft: '71pt' }}
                    >
                      <ul className="navigation-menu ocultarenmobile">
                        {this.state.navItems.map((item, key) => (
                          <li
                            key={key}
                            className={item.navheading === "Home" ? "active" : ""}
                          >
                            <a href={"#" + item.idnm}> {item.navheading}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </ScrollspyNav>

                </Col>
                <div
                  id={'navegation_mobile_new'}
                  style={{
                    backgroundColor: 'white',
                    width: '105%',
                    marginLeft: '-19px',
                    display: this.state.isOpen ? "block" : "none"
                  }}

                >
                  <ScrollspyNav
                    scrollTargetIds={targetId}
                    scrollDuration="300"
                    headerBackground="false"
                    activeNavClass="active"
                  >
                    {/* mobile menu */}
                    <div id="navigation" className="mobileNavPos" style={{ display: this.state.isOpen ? "block" : "" }} >
                      <ul className="navigation-menu " style={{ height: 'auto' }}>
                        {this.state.navItems.map((item, key) => (
                          <li key={key} className={item.navheading === "Home" ? "active" : ""} style={{ padding: '5px' }}>
                            <a href={"#" + item.idnm} onClick={this.toggleLine}> {item.navheading}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </ScrollspyNav>
                </div>
                <Col
                  style={{ backgroundColor: 'white' }}
                  className={'hideMobile despegado despegadoFIX'}
                  lg={6}
                >

                  <div
                    id="navigation"
                    style={{ display: this.state.isOpen ? "block" : "none" }}
                  >
                    <ul className="navigation-menu" id="navegacionDirecc" style={{ float: 'left' }}>
                      <li id="direccion" style={{ color: 'rgb(111,111,111)', cursor: 'default', paddingTop: '35px', fontSize: '15px', fontFamily: "GT-America-Regular" }}>Huergo 475 - Paseo del Bajo</li>
                      {/*                       <li key={1} className={""}><a href={"#ubicacion"} className="subrayado" style={{ color: 'black', textDecoration: 'underline!important', textTransform: 'none!important' }}>Ver en mapa</a> </li>
 */}                    </ul>

                  </div>
                  <div
                    style={{ textAlign: 'right', marginRight: '71pt' }}
                  >
                    {/*                     <img className={'iconoContacto'} src={contactoo} id="navegacionDirecc" />
 */}
                    <Link to="#contacto" className="btn btn-custom-black" id="btnContacto" style={{ marginTop: '20px', padding: '5px 22px' }}>Contacto</Link>

                  </div>

                </Col>

              </Row>
            </Container>
          </header>
        </React.Fragment >
        :

        <React.Fragment>
          <header
            id="topnav"
            className={this.props.isNotSticky === true ? "defaultscroll sticky " + this.props.navclass : "defaultscroll fixed-top sticky " + this.props.navclass}
          >
            <Container className="containerFull">
              <Row className="alturaMenu">


                <Col
                  style={{ backgroundColor: 'transparent' }}
                  className={'hideDesktop showMobile'}
                >
                  <div className={'posMargen'}>
                    <Link to="/" className="logo text-uppercase"> <img src={logolight} alt="" id="logotipo" className="logo-light logotipo" height="40" /></Link>
                  </div>
                </Col>

                <Col
                  className={'fondo despegado'}
                  xs={3}
                  lg={7}

                >
                  <div className={'posMargen hideMobile'}>
                    <Link to="/" className="logo text-uppercase"> <img src={logolight} alt="" id="logotipo" className="logo-light logotipo" height="40" /></Link>
                  </div>
                  <div className="menu-extras">
                    <div className="menu-item">
                      <Link to="#" onClick={this.toggleLine} className={this.state.isOpen ? "navbar-toggle open" : "navbar-toggle"} >
                        <div className="lines">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <BrowserView>
                    <ScrollspyNav
                      scrollTargetIds={targetId}
                      scrollDuration="300"
                      headerBackground="false"
                      activeNavClass="active"
                    >
                      <div id="navigation  ocultarenmobile"
                        style={{ display: this.state.isOpen ? "block" : "", marginLeft: '71pt' }}
                      >
                        <ul className="navigation-menu ">
                          {this.state.navItems.map((item, key) => (
                            <li
                              key={key}
                              className={item.navheading === "Home" ? "active" : ""}
                            >
                              <a href={"#" + item.idnm}> {item.navheading}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </ScrollspyNav>
                  </BrowserView>
                </Col>
                <div
                  id={'navegation_mobile_new'}
                  style={{
                    backgroundColor: 'white',
                    width: '105%',
                    marginLeft: '-19px',
                    display: this.state.isOpen ? "block" : "none"
                  }}

                >

                  <ScrollspyNav
                    scrollTargetIds={targetId}
                    scrollDuration="300"
                    headerBackground="false"
                    activeNavClass="active"
                  >
                    {/*menu mobile show*/}
                    <div id="navigation" className="mobileNavPos" style={{ display: this.state.isOpen ? "block" : "" }} >
                      <ul className="navigation-menu " style={{ height: 'auto' }}>
                        {this.state.navItems.map((item, key) => (
                          <li key={key} className={item.navheading === "Home" ? "active" : ""} style={{ padding: '5px' }}>
                            <a href={"#" + item.idnm} onClick={this.toggleLine}> {item.navheading}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </ScrollspyNav>
                </div>
                <Col
                  style={{ backgroundColor: 'white' }}
                  className={'hideMobile despegado'}
                  lg={5}
                >

                  <div
                    id="navigation"
                    style={{ display: this.state.isOpen ? "block" : "none" }}
                  >
                    <ul className="navigation-menu" id="navegacionDirecc" style={{ float: 'left' }}>
                      <li id="direccion" style={{ color: 'rgb(111,111,111)', cursor: 'default', paddingTop: '35px', fontSize: '15px', fontFamily: "GT-America-Regular" }}>Huergo 475 - Paseo del Bajo</li>
                      {/*                       <li key={1} className={""}><a href={"#"} className="subrayado" style={{ color: 'black', textDecoration: 'underline!important', textTransform: 'none!important' }}>Ver en mapa</a> </li>
 */}                    </ul>

                  </div>
                  <div
                    style={{ textAlign: 'right', marginRight: '71pt' }}
                  >
                    {/*                     <img className={'iconoContacto'} src={contactoo} id="navegacionDirecc" />
 */}
                    <Link to="#contacto" className="btn btn-custom-black" id="btnContacto" style={{ marginTop: '20px', padding: '5px 22px' }}>Contacto</Link>

                  </div>

                </Col>

              </Row>
            </Container>
          </header>
        </React.Fragment >


    );
  }
}

export default NavbarPage;
