import React, { Component } from "react";

import NavbarPage from "../../components/Navbar/Navbar_Page";

import ProyectosHuergoVideo from "../../components/ProyectosHuergoVideo";

import ProyectosHuergo from "../../components/ProyectosHuergo";
import UnidadesHuergo from "../../components/UnidadesHuergo/index2.js";
import ContactoHuergo from "../../components/ContactoHuergo";
import Section from "./section";
import EdificioMapper from "./EdificioMapper";
import UbicacionHuergo from "../../components/UbicacionHuergo";
import Footer from "../../components/Footer/footer";

import TablaUnidades from "../../components/TablaUnidades";




const enviroment = 'https://rvgdg1p2a1.execute-api.us-east-2.amazonaws.com/Staging'
//const enviroment = 'https://ewomyq439l.execute-api.us-east-2.amazonaws.com/prod'

// API'S
const apiDatosLanding = enviroment + '/api/sysparam?code=';
//const apiProductType = enviroment + '/api/producttype/landing';
//const apiBarrios = enviroment + '/api/Entrepreneurship/landing/PUERTOS';
const apiMailing = enviroment + '/api/vendor/landing-ticket/huergo';


class Index1 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: "",
      numContacto: null,
    };
  }




  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
    this.fetchNumTel();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;

    if (scrollup > this.state.pos) {
      this.setState({ navClass: "darkheader", imglight: false });

    } else {
      this.setState({ navClass: "", imglight: true });
    }
  };


  fetchNumTel() {
    fetch(apiDatosLanding + 'LandingHuergoContactDestinationPhoneNumber').then(response => response.json()).then(data => this.setState({ numContacto: data.value }));
  }

  render() {
    return (
      <React.Fragment>
        <NavbarPage
          navclass={this.state.navClass}
          imglight={this.state.imglight}
        />
        <Section numContacto={this.state.numContacto} />
        <ProyectosHuergoVideo />
        <ProyectosHuergo />
        <EdificioMapper />
        <UnidadesHuergo />
        <UbicacionHuergo />
        <ContactoHuergo apiMailing={apiMailing} />
        <Footer numContacto={this.state.numContacto} />
      </React.Fragment>
    );
  }
}

export default Index1;
