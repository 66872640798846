import React, { Component } from "react";

class Square extends Component {



    constructor(props) {
        super(props);
   
      }

    render() {

        return (
            <div
                className={'squareBottom'}
                style={{
                    width: this.props.ancho,
                    height: this.props.alto,
                    top: this.props.top,
                    bottom: this.props.bottom
                }}
            >

            </div>
        );
    }
}

export default Square;