import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Footer link
import FooterLinks from "./footer-links";
import logolight from "../../assets/images/H475.svg";


import wp from "../../assets/images/logo_whatsapp.svg";
import ig from "../../assets/images/ig.png";
import fb from "../../assets/images/fb.png";


import criba from "../../assets/images/logo_criba.png";

import consultatio from "../../assets/images/logo_consultatio.svg";



class Footer extends Component {
    state = {
        footerItems: [
            {
                title: "about Linexon",
                links: [
                    { linkTitle: "About Us", link: "#" },
                    { linkTitle: "Press", link: "#" },
                    { linkTitle: "Job Opening", link: "#" },
                    { linkTitle: "Terms", link: "#" },
                    { linkTitle: "Privacy", link: "#" },
                ]
            },
            {
                title: "resaurses",
                links: [
                    { linkTitle: "Blog", link: "#" },
                    { linkTitle: "Help Center", link: "#" },
                    { linkTitle: "Academy", link: "#" },
                    { linkTitle: "Video Terms", link: "#" },
                    { linkTitle: "Monitoring Grader", link: "#" },
                    { linkTitle: "Linexon API", link: "#" },
                ]
            },
            {
                title: "more info",
                links: [
                    { linkTitle: "Pricing", link: "#" },
                    { linkTitle: "For Marketing", link: "#" },
                    { linkTitle: "For CEOs", link: "#" },
                    { linkTitle: "For Agencies", link: "#" },
                    { linkTitle: "Our Apps", link: "#" },
                ]
            },
        ]
    }
    render() {
        return (
            <React.Fragment>
                <section className="section bg-darkkk sectionFooter">
                    <Container className="containerFull">
                        <Row className="footerRow">
                            <Col className="posMargen" xs={12} lg={1}>
                                <img src={logolight} width="54" />
                            </Col>

                            <Col xs={12} lg={2}>
                                <p className="footerDesc">Huergo 475 - Paseo del Bajo</p>
                                <p className="footerDesc">Buenos Aires, Argentina</p>

                            </Col>

                            <Col xs={12} lg={2} className="footerRedes">
                                <Col>
                                    <a href={'https://api.whatsapp.com/send?phone=' + this.props.numContacto + '&source=&data=&app_absent='}>
                                        <img src={wp} height="27" style={{ marginRight: '15px' }} />
                                    </a>
                                    <img src={ig} height="27" />
                                </Col>

                            </Col>

                            <hr className="hrMobiTop" />


                            <Col xs={12} lg={5} style={{ marginTop: '-15px' }}>
                                <Row>
                                    <Col xs={12} lg={4} className="borderFooter">
                                        <div className="empresaLogo "><img src={consultatio} height="auto" /></div>
                                    </Col>
                                    <Col xs={12} lg={4} className="borderFooter">
                                        <div className="empresaLogo "><img src={criba} height="22" /></div>
                                    </Col>
                                    <Col xs={12} lg={4} >
                                        <div className="empresaLogo " className="faidenAdamo">{'adamo-faiden'}</div>
                                    </Col>
                                    <hr className="hrMobi" style={{ marginTop: '1rem' }} />
                                </Row>
                            </Col>


                            <Col xs={12} lg={1} className="footLegal">
                                <p className="footerDesc">Legal</p>
                                <p className="footerDesc">Site Credits</p>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {
                    this.props.numContacto ?
                        <a href={'https://api.whatsapp.com/send?phone=' + this.props.numContacto + '&source=&data=&app_absent='} className="whatsappFooter" target="blank">
                            <img src='/assets/images/whatsapp-512.png' className="whatsappimgFooter" />
                        </a>
                        :
                        null
                }
            </React.Fragment>
        );
    }
}

export default Footer;