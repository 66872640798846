import React, { Component } from 'react';
import ImageMapper from 'react-image-mapper';


import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

import $ from "jquery";

/** 
 *    <area shape="poly" coords="703, 616, 741, 621, 778, 613, 778, 637, 740, 647, 703, 642" />
    <area shape="poly" coords="608, 693, 718, 711, 718, 745, 608, 725" />
    <area shape="poly" coords="607, 540, 655, 544, 655, 569, 607, 564" />
    <area shape="poly" coords="574, 173, 705, 161, 757, 173, 757, 225, 741, 221, 574, 233" />
 * 
*/


class Section extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
            width: window.innerWidth,
            height: 0,
            showPopUp: false,

            preFillColor: "rgb(37 37 37 / 0%)",
            fillColor: "rgb(37 37 37 / 0%)",
            strokeColor: "rgb(37 37 37 / 0%)",
            lineWidth: 1,

            MAP: {
                name: "my-map",
                areas: [
                    {
                        name: "Roof", desc: "Kitchen & laundry area",
                        img: "assets/roof.png",
                        shape: "poly",
                        coords: [540, 126, 681, 116, 738, 127, 738, 177, 682, 177, 615, 181, 615, 159, 540, 163, 540, 144],

                    },
                    {
                        name: "Lab", desc: "Kitchen & laundry area",
                        img: "assets/images/lab.jpg", shape: "poly",
                        coords: [578, 524, 626, 528, 626, 556, 579, 552],
             
                    },
                    {
                        name: "Club", desc: "Kitchen & laundry area",
                        img: "assets/images/club.jpg",
                        shape: "poly",
                        coords: [705, 637, 760, 646, 780, 637, 782, 662, 761, 668, 704, 660],
                  
                    },
                    {
                        name: "Plaza", desc: "Kitchen & laundry area",
                        img: "assets/images/plaza.jpg",
                        shape: "poly",
                        coords: [635, 704, 735, 723, 736, 748, 636, 730],
         
                    },
                ],
            },


            /*           MAPMOBILE: {
                          name: "my-map",
                          areas: [
                              { name: "Roof", desc: "Kitchen & laundry area", img: "assets/images/roof_amenities.jpg", shape: "poly", coords: [220, 174, 221, 236, 352, 226, 401, 226, 403, 174, 350, 161], preFillColor: "rgb(128, 224, 167)", fillColor: "black" },
                              { name: "Lab", desc: "Kitchen & laundry area", img: "assets/images/roof_amenities.jpg", shape: "poly", coords: [255, 540, 301, 545, 301, 570, 253, 564], preFillColor: "rgb(128, 224, 167)", fillColor: "black" },
                              { name: "Club", desc: "Kitchen & laundry area", img: "assets/images/imagen_interactiva.jpg", shape: "poly", coords: [350, 618, 350, 640, 386, 646, 423, 639, 424, 617, 387, 624], preFillColor: "rgb(128, 224, 167)", fillColor: "black" },
                              { name: "Plaza", desc: "Kitchen & laundry area", img: "assets/images/imagen_interactiva.jpg", shape: "poly", coords: [252, 689, 366, 707, 366, 742, 251, 721], preFillColor: "rgb(128, 224, 167)", fillColor: "black" },
                          ]
                      } */

        }
        this.enterArea.bind(this)
        this.leaveArea.bind(this)
        this.getTipPosition.bind(this)
        this.updateWindowDimensions.bind(this)
    }

    componentDidMount() {

        this.ImageMapResize();
    }

    ImageMapResize() {
        var previousWidth = 1440;
        var n, m, clen, x = window.innerWidth / previousWidth;

        let coord1 = this.state.MAP.areas[0].coords;
        clen = coord1.length;
        for (m = 0; m < clen; m++) {
            coord1[m] *= x;
        }

        let coord2 = this.state.MAP.areas[1].coords;
        clen = coord2.length;
        for (m = 0; m < clen; m++) {
            coord2[m] *= x;
        }

        let coord3 = this.state.MAP.areas[2].coords;
        clen = coord3.length;
        for (m = 0; m < clen; m++) {
            coord3[m] *= x;
        }

        let coord4 = this.state.MAP.areas[3].coords;
        clen = coord4.length;
        for (m = 0; m < clen; m++) {
            coord4[m] *= x;
        }


        this.setState({
            MAP: {
                name: "my-map",
                areas: [
                    {
                        name: "Roof", ubi: 'tooltipMapper', desc: "Kitchen & laundry area", img: "/assets/images/roof.png", shape: "poly", coords: coord1,
                        preFillColor: this.state.preFillColor,
                        fillColor: this.state.fillColor,
                        strokeColor: this.state.strokeColor,
                        lineWidth: this.state.lineWidth
                    },
                    {
                        name: "Lab", ubi: 'tooltipMapper', desc: "Kitchen & laundry area", img: "/assets/images/lab.png", shape: "poly", coords: coord2, preFillColor: this.state.preFillColor,
                        fillColor: this.state.fillColor,
                        strokeColor: this.state.strokeColor,
                        lineWidth: this.state.lineWidth
                    },
                    {
                        name: "Club", ubi: 'tooltipMapperSide', desc: "Kitchen & laundry area", img: "/assets/images/club.png", shape: "poly", coords: coord3, preFillColor: this.state.preFillColor,
                        fillColor: this.state.fillColor,
                        strokeColor: this.state.strokeColor,
                        lineWidth: this.state.lineWidth
                    },
                    {
                        name: "Plaza", ubi: 'tooltipMapperSide', desc: "Kitchen & laundry area", img: "/assets/images/plaza.png", shape: "poly", coords: coord4, preFillColor: this.state.preFillColor,
                        fillColor: this.state.fillColor,
                        strokeColor: this.state.strokeColor,
                        lineWidth: this.state.lineWidth
                    },
                ],
            }
        });

        previousWidth = window.innerWidth;

    }




    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
        console.log('Ancho', this.state)
    }

    enterArea(area) {
        this.setState({ hoveredArea: area, showPopUp: true });
        console.log(area)
    }

    leaveArea(area) {
        this.setState({ hoveredArea: null, showPopUp: false });
    }

    getTipPosition(area) {
        return { top: `${area.center[1]}px`, left: `${area.center[0]}px` };
    }

    tolowerrrr(str) {
        return str.toLowerCase();
    }

    render() {

        const URL = "./assets/images/mapper_edificio.png"
        const URL_mobile = "./assets/images/mapper_edificio_mobile.png"

        // 1200px - 480
        // ANCHO - XXX = ANCHO X VALUE / 1200PX

        console.log(this.state.hoveredArea);
        return (
            <React.Fragment>



                <BrowserView>
                    <div className="containerMApper" >
                        <ImageMapper
                            src={URL}
                            map={this.state.MAP}
                            width={this.state.width}
                            imgWidth={this.state.width}
                            onMouseEnter={area => this.enterArea(area)}
                            onMouseLeave={area => this.leaveArea(area)}
                        />

                        {
                            this.state.hoveredArea &&
                            <div className={this.state.hoveredArea.ubi} style={{ ...this.getTipPosition(this.state.hoveredArea) }}>
                                <img className="imgMappera" src={this.state.hoveredArea.img} style={{ width: '130%' }}></img>
                                {/*   <h3 className="tituloMapper">{this.state.hoveredArea.name}</h3>1
                                <h4 className="descMapper">{this.state.hoveredArea.desc}</h4>*/}
                            </div>
                        }
                    </div>
                    <div id="cajaEdiffmapper"></div>
                </BrowserView>


                {/*          <MobileView>
                    <div className="containerMApper" >
                        <ImageMapper
                            src={URL_mobile}
                            map={this.state.MAPMOBILE}
                            width={this.state.width}
                            imgWidth={657}
                            onMouseEnter={area => this.enterArea(area)}
                            onMouseLeave={area => this.leaveArea(area)}
                        />

                        {
                            this.state.hoveredArea &&
                            <div className="tooltipMapper" style={{ ...this.getTipPosition(this.state.hoveredArea) }}>
                               <img className="imgMapper" src={this.state.hoveredArea.img}></img>
                                <h3 className="tituloMapper">{this.state.hoveredArea.name}</h3>
                                <h4 className="descMapper">{this.state.hoveredArea.desc}</h4>
                            </div>
                        }
                    </div>
                </MobileView>
                    */}
            </React.Fragment>
        );
    }
}

export default Section;