import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";

import img1 from "../../assets/images/fondo_pto_madero.png";
import img2 from "../../assets/images/fondo_pto_madero.png";
import img3 from "../../assets/images/fondo_pto_madero.png";

class Clients extends Component {

  state = {

    imagenes: [
      './assets/images/slider_footer/8.30.jpg',
      './assets/images/slider_footer/10.10.jpg',
      './assets/images/slider_footer/11.26.jpg',
      './assets/images/slider_footer/12.58.jpg',
      './assets/images/slider_footer/16.0.jpg',
      './assets/images/slider_footer/20.30.jpg',
    ]

    ,
  };

  render() {

    const customRenderThumb = (children) => null;
    const mainGroupId = 'Main';

    const arrowStyles: CSSProperties = {
      position: 'absolute',
      zIndex: 2,
      top: 'calc(100% - 88px)',
      right: '74px',
      width: 53,
      height: 53,
      cursor: 'pointer',
      backgroundColor: 'rgb(255, 255, 255)',
      border: '0px'

    };

    const arrowStylesIzq: CSSProperties = {
      position: 'absolute',
      zIndex: 2,
      top: 'calc(100% - 88px)',
      right: 'calc(100% - 127px)',
      width: 53,
      height: 53,
      cursor: 'pointer',
      backgroundColor: 'rgb(128, 224, 167)',
      border: '0px'
    };



    return (
      <React.Fragment>


        <BrowserView>
          <section className="section" id="ubicacion">
            <Container>
              <h2 className="tituloSeccion">Acá vivimos</h2>
              <br />
              <p className="descSeccion">Ubicado en avenida Ingeniero Huergo 475, se encuentra en el borde que comunica<br />al barrio más antiguo con el más joven de Buenos Aires.</p>
              <br />
              <br />
            </Container>

            <Container fluid>
              <Row style={{ margin: '0px' }} className="ubicacionRow">
                <Col lg={'12'} xs={'12'} className="carroNoPadds" >

                  <Carousel

                    infiniteLoop
                    centerMode


                    renderThumbs={customRenderThumb}
                    statusFormatter={(current, total) => ''}
                    renderArrowPrev={(onClickHandler, hasPrev, label) => {
                      if (!isMobile) {
                        return (<button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStylesIzq }}><BsChevronLeft /></button>)
                      } else {
                        return (null)
                      }
                    }
                    }
                    renderArrowNext={(onClickHandler, hasNext, label) => {
                      if (!isMobile) {
                        return (<button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles }}><BsChevronRight /></button>)
                      } else {
                        return (null)
                      }
                    }
                    }
                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                      return (null);
                    }}
                  >
                    {this.state.imagenes.map((item, key) => (

                      <div><img src={item} style={{ width: '95%' }}></img></div>
                    ))}
                  </Carousel>
                </Col>
              </Row>
            </Container>

            <Container>
              <Row className="mappa">
                <Col ls="12">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.6475570745665!2d-58.369372484293024!3d-34.61307268045707!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a3352a0bb1f495%3A0x6d45e22ce854f29f!2sAv.%20Ing%20Huergo%20475%2C%20C1107%20AOE%2C%20Buenos%20Aires!5e0!3m2!1sen!2sar!4v1607960918559!5m2!1sen!2sar"
                    width="100%"
                    height="600px"
                    frameborder="0"
                    style={{ border: '0' }}
                    allowfullscreen=""
                    aria-hidden="false"
                    id="mappGoogle"
                    tabindex="0">
                  </iframe>
                </Col>
              </Row>
            </Container>

          </section>
        </BrowserView>



        <MobileView>
          <section className="section" id="ubicacion">
            <Container>
              <h2 className="tituloSeccion">Acá vivimos</h2>
              <br />
              <p className="descSeccion">Ubicado en avenida Ingeniero Huergo<br /> 475, se encuentra en el borde que<br /> comunicaal barrio más antiguo con el<br /> más joven de Buenos Aires.</p>
              <br />
              <br />
            </Container>
            <Container className='container_footer_carr' style={{ textAlign: 'left', padding: '0px', marginBottom: '30px' }}>
              <Carousel
                infiniteLoop
                centerMode
                renderThumbs={customRenderThumb}
                statusFormatter={(current, total) => ''}
                renderArrowPrev={(onClickHandler, hasPrev, label) => {
                  if (!isMobile) {
                    return (<button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStylesIzq }}><BsChevronLeft /></button>)
                  } else {
                    return (null)
                  }
                }
                }
                renderArrowNext={(onClickHandler, hasNext, label) => {
                  if (!isMobile) {
                    return (<button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 0 }}><BsChevronRight /></button>)
                  } else {
                    return (null)
                  }
                }
                }
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                  return (null);
                }}
              >
                {this.state.imagenes.map((item, key) => (
                  <img src={item} />
                ))}
              </Carousel>

            </Container>

            <Container>
              <Row className="mappa">
                <Col ls="12">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.6475570745665!2d-58.369372484293024!3d-34.61307268045707!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a3352a0bb1f495%3A0x6d45e22ce854f29f!2sAv.%20Ing%20Huergo%20475%2C%20C1107%20AOE%2C%20Buenos%20Aires!5e0!3m2!1sen!2sar!4v1607960918559!5m2!1sen!2sar"
                    width="100%"
                    height="295px"
                    frameborder="0"
                    style={{ border: '0' }}
                    allowfullscreen=""
                    aria-hidden="false"
                    id="mappGoogle"
                    tabindex="0"></iframe>
                </Col>
              </Row>
            </Container>
          </section>
        </MobileView>

      </React.Fragment >
    );
  }
}

export default Clients;
