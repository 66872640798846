import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";



import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import { BsChevronRight, BsChevronLeft, BsChevronDown, BsChevronUp } from "react-icons/bs";



class ProyectoHuergo extends Component {

  constructor(props) {
    super(props);
    this.state = {


      tabItems: [
        {
          id: 1, titulo: "Plaza",
          desc: "",
          imgs: [
            './assets/images/new_plaza.jpg',
            './assets/images/plaza/plaza2.jpg',
            './assets/images/plaza/plaza3.jpg',
          ]
        },
        {
          id: 2, titulo: "Club",
          desc: "",
          imgs: [
            './assets/images/club/club1.jpg',
            './assets/images/club/club2.jpg',
            './assets/images/club/club3.jpg',
          ]
        },
        {
          id: 3, titulo: "Lab",
          desc: "",
          imgs: [
            './assets/images/lab/lab.jpg',
            './assets/images/lab/lab2.jpg',
          ]
        },
        {
          id: 4, titulo: "Roof",
          desc: "",
          imgs: [
            './assets/images/roof/roof1.jpg',
            './assets/images/roof/roof2.jpg',
            './assets/images/roof/roof3.jpg',
          ]
        },
        /*    {
             id: 5,
             titulo: "Lounge",
             desc: "",
             imgs: [
               './assets/images/lounge/lounge1.jpg',
               './assets/images/lounge/lounge2.jpg',
   
             ]
           } */
      ],

      active: 0,

      isOpen: true,
    };

    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(id) {

    this.setState({ active: id })
  }



  render() {


    const customRenderThumb = (children) => null;

    const arrowStyles: CSSProperties = {
      position: 'absolute',
      zIndex: 2,
      top: 'calc(100% - 53px)',
      width: 53,
      height: 53,
      cursor: 'pointer',
      backgroundColor: 'rgb(255, 255, 255)',
      border: '0px'

    };

    const arrowStylesIzq: CSSProperties = {
      position: 'absolute',
      zIndex: 2,
      top: 'calc(100% - 53px)',
      right: '53px',
      width: 53,
      height: 53,
      cursor: 'pointer',
      backgroundColor: 'rgb(128, 224, 167)',
      border: '0px'
    };



    return (
      <React.Fragment>
        <div id='cajaAmenities'></div>

        <section className="section" id="proyecto" style={{ backgroundColor: 'rgba(217, 234, 224, 0.74)' }}>

          <BrowserView>
            <Container>
              <h2 className="tituloSeccion">Viví en 48.000m2</h2>
              <br />
              <Row className="mt-4" style={{ justifyContent: 'center' }}>

                <Col lg={'11'} xs={'11'} className="mt-2 separacion tabsClickeables" >
                  <div><h1 onClick={() => this.toggleTab(0)} className={this.state.active == 0 ? 'clickeableTab activeTab' : 'clickeableTab'} style={{ textAlign: 'left' }}>Plaza</h1></div>
                  <div><h1 onClick={() => this.toggleTab(1)} className={this.state.active == 1 ? 'clickeableTab activeTab' : 'clickeableTab'}>Club</h1></div>
                  <div><h1 onClick={() => this.toggleTab(2)} className={this.state.active == 2 ? 'clickeableTab activeTab' : 'clickeableTab'}>Lab</h1></div>
                  <div><h1 onClick={() => this.toggleTab(3)} className={this.state.active == 3 ? 'clickeableTab activeTab' : 'clickeableTab'} style={{ textAlign: 'right' }}>Roof</h1></div>
                  {/*                   <div><h1 onClick={() => this.toggleTab(4)} className={this.state.active == 4 ? 'clickeableTab activeTab' : 'clickeableTab'} style={{ textAlign: 'right' }}>Lounge</h1></div>
 */}                </Col>
                <br />
                <Col lg={'12'} xs={'12'} className="mt-2">
                  <Carousel
                    infiniteLoop={true}
                    renderThumbs={customRenderThumb}
                    statusFormatter={(current, total) => ''}
                    renderArrowPrev={(onClickHandler, hasPrev, label) => {
                      if (!isMobile) {
                        return (<button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStylesIzq }}><BsChevronLeft /></button>)
                      } else {
                        return (null)
                      }
                    }
                    }
                    renderArrowNext={(onClickHandler, hasNext, label) => {
                      if (!isMobile) {
                        return (<button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 0 }}><BsChevronRight /></button>)
                      } else {
                        return (null)
                      }
                    }
                    }
                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                      return (null);
                    }}
                  >
                    {this.state.tabItems[this.state.active].imgs.map((item, key) => (

                      <div>
                        <img alt="" src={item} />
                      </div>

                    ))}
                  </Carousel>
                </Col>



              </Row>
            </Container>
          </BrowserView>

          <MobileView>
            <Container style={{ padding: '0px' }}>

              <Row style={{ margin: '0px' }}>

                <h2 className="tituloSeccion">Viví en 48.000m2</h2>
                <br />
                <br />

                <>
                  <Col lg={'12'} xs={'12'} style={{ paddingRight: '34px', paddingLeft: '34px' }}>
                    <div className={this.state.isOpen && this.state.active == 0 ? 'lineaTabla LineaAbierta' : 'lineaTabla'}>
                      <div className="tablaUnidadesCol1">Plaza</div>
                      <div className="btnCollapseTabla"> {!this.state.open ? <BsChevronDown onClick={() => this.toggleTab(0)} /> : <BsChevronUp onClick={() => this.toggleTab()} />} </div>
                    </div>
                  </Col>


                  {this.state.isOpen && this.state.active == 0 ?
                    <div>
                      {
                        <Carousel infiniteLoop={true} renderThumbs={customRenderThumb} statusFormatter={(current, total) => ''} renderArrowPrev={(onClickHandler, hasPrev, label) => { if (!isMobile) { return (<button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStylesIzq }}><BsChevronLeft /></button>) } else { return (null) } }}
                          renderArrowNext={(onClickHandler, hasNext, label) => {
                            if (!isMobile) { return (<button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 0 }}><BsChevronRight /></button>) } else {
                              return (null)
                            }
                          }
                          }
                          renderIndicator={(onClickHandler, isSelected, index, label) => {
                            return (null);
                          }}
                        >
                          {this.state.tabItems[this.state.active].imgs.map((item, key) => (
                            <div><img alt="" src={item} /></div>
                          ))}
                        </Carousel>
                      }
                    </div >
                    :
                    null
                  }
                </>

                <>
                  <Col lg={'12'} xs={'12'} style={{ paddingRight: '34px', paddingLeft: '34px' }}>
                    <div className={this.state.isOpen && this.state.active == 1 ? 'lineaTabla LineaAbierta' : 'lineaTabla'}>
                      <div className="tablaUnidadesCol1">Club</div>
                      <div className="btnCollapseTabla"> {!this.state.open ? <BsChevronDown onClick={() => this.toggleTab(1)} /> : <BsChevronUp onClick={() => this.toggleTab()} />} </div>
                    </div>
                  </Col>

                  {this.state.isOpen && this.state.active == 1 ?
                    <div>
                      {
                        <Carousel infiniteLoop={true} renderThumbs={customRenderThumb} statusFormatter={(current, total) => ''} renderArrowPrev={(onClickHandler, hasPrev, label) => { if (!isMobile) { return (<button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStylesIzq }}><BsChevronLeft /></button>) } else { return (null) } }}
                          renderArrowNext={(onClickHandler, hasNext, label) => {
                            if (!isMobile) { return (<button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 0 }}><BsChevronRight /></button>) } else {
                              return (null)
                            }
                          }
                          }
                          renderIndicator={(onClickHandler, isSelected, index, label) => {
                            return (null);
                          }}
                        >
                          {this.state.tabItems[this.state.active].imgs.map((item, key) => (
                            <div><img alt="" src={item} /></div>
                          ))}
                        </Carousel>
                      }
                    </div >
                    :
                    null
                  }
                </>
                <>
                  <Col lg={'12'} xs={'12'} style={{ paddingRight: '34px', paddingLeft: '34px' }}>
                    <div className={this.state.isOpen && this.state.active == 2 ? 'lineaTabla LineaAbierta' : 'lineaTabla'}>
                      <div className="tablaUnidadesCol1">Lab</div>
                      <div className="btnCollapseTabla"> {!this.state.open ? <BsChevronDown onClick={() => this.toggleTab(2)} /> : <BsChevronUp onClick={() => this.toggleTab()} />} </div>
                    </div>
                  </Col>

                  {this.state.isOpen && this.state.active == 2 ?
                    <div>
                      {
                        <Carousel infiniteLoop={true} renderThumbs={customRenderThumb} statusFormatter={(current, total) => ''} renderArrowPrev={(onClickHandler, hasPrev, label) => { if (!isMobile) { return (<button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStylesIzq }}><BsChevronLeft /></button>) } else { return (null) } }}
                          renderArrowNext={(onClickHandler, hasNext, label) => {
                            if (!isMobile) { return (<button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 0 }}><BsChevronRight /></button>) } else {
                              return (null)
                            }
                          }
                          }
                          renderIndicator={(onClickHandler, isSelected, index, label) => {
                            return (null);
                          }}
                        >
                          {this.state.tabItems[this.state.active].imgs.map((item, key) => (
                            <div><img alt="" src={item} /></div>
                          ))}
                        </Carousel>
                      }
                    </div >
                    :
                    null
                  }
                </>
                <>
                  <Col lg={'12'} xs={'12'} style={{ paddingRight: '34px', paddingLeft: '34px' }}>
                    <div className={this.state.isOpen && this.state.active == 3 ? 'lineaTabla LineaAbierta' : 'lineaTabla'}>
                      <div className="tablaUnidadesCol1">Roof</div>
                      <div className="btnCollapseTabla"> {!this.state.open ? <BsChevronDown onClick={() => this.toggleTab(3)} /> : <BsChevronUp onClick={() => this.toggleTab()} />} </div>
                    </div>
                  </Col>

                  {this.state.isOpen && this.state.active == 3 ?
                    <div>
                      {
                        <Carousel infiniteLoop={true} renderThumbs={customRenderThumb} statusFormatter={(current, total) => ''} renderArrowPrev={(onClickHandler, hasPrev, label) => { if (!isMobile) { return (<button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStylesIzq }}><BsChevronLeft /></button>) } else { return (null) } }}
                          renderArrowNext={(onClickHandler, hasNext, label) => {
                            if (!isMobile) { return (<button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 0 }}><BsChevronRight /></button>) } else {
                              return (null)
                            }
                          }
                          }
                          renderIndicator={(onClickHandler, isSelected, index, label) => {
                            return (null);
                          }}
                        >
                          {this.state.tabItems[this.state.active].imgs.map((item, key) => (
                            <div><img alt="" src={item} /></div>
                          ))}
                        </Carousel>
                      }
                    </div >
                    :
                    null
                  }
                </>
                {/*   <>
                  <Col lg={'12'} xs={'12'} style={{ paddingRight: '34px', paddingLeft: '34px' }}>
                    <div className={this.state.isOpen && this.state.active == 4 ? 'lineaTabla LineaAbierta' : 'lineaTabla'}>
                      <div className="tablaUnidadesCol1">Lounge</div>
                      <div className="btnCollapseTabla"> {!this.state.open ? <BsChevronDown onClick={() => this.toggleTab(4)} /> : <BsChevronUp onClick={() => this.toggleTab()} />} </div>
                    </div>
                  </Col>

                  {this.state.isOpen && this.state.active == 4 ?
                    <div>
                      {
                        <Carousel infiniteLoop={true} renderThumbs={customRenderThumb} statusFormatter={(current, total) => ''} renderArrowPrev={(onClickHandler, hasPrev, label) => { if (!isMobile) { return (<button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStylesIzq }}><BsChevronLeft /></button>) } else { return (null) } }}
                          renderArrowNext={(onClickHandler, hasNext, label) => {
                            if (!isMobile) { return (<button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 0 }}><BsChevronRight /></button>) } else {
                              return (null)
                            }
                          }
                          }
                          renderIndicator={(onClickHandler, isSelected, index, label) => {
                            return (null);
                          }}
                        >
                          {this.state.tabItems[this.state.active].imgs.map((item, key) => (
                            <div><img alt="" src={item} /></div>
                          ))}
                        </Carousel>
                      }
                    </div >
                    :
                    null
                  }
                </> */}



              </Row>
            </Container>
          </MobileView>






          {/*    <Container>
            <Row style={{ justifyContent: 'flex-end' }}>
              <Col lg={'9'} xs={'12'} className="mt-2">
                <div className={'footerDescCarrousel'}>
                  <p>{this.state.tabItems[this.state.active].desc}</p>
                </div>
              </Col>
            </Row>
            </Container> 
          */}
        </section>
      </React.Fragment >
    );
  }
}

export default ProyectoHuergo;
