import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";


import { BsChevronDown } from "react-icons/bs";

import RowItem from "./RowItem.js";

class TablaUnidades extends Component {

  state = {
    Entrepreneurship: null,
  };

  async componentDidMount() {
    try {
      const responseE = await fetch('https://rvgdg1p2a1.execute-api.us-east-2.amazonaws.com/Staging/api/Entrepreneurship/landing/huergo');
      const entrepreneurship = await responseE.json();
      this.setState({ Entrepreneurship: entrepreneurship });

      if (!entrepreneurship) {
        console.error(entrepreneurship);
      }

    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
        <Row className="mappa">
          <Col ls="12">
            {this.state.Entrepreneurship ?
              <>
                <RowItem
                  title="1 ambiente"
                  key="1amb"
                  ambiences="1"
                  entrepreneurship={this.state.Entrepreneurship}
                />

                <RowItem
                  title="2 ambientes"
                  key="2amb"
                  ambiences="2"
                  entrepreneurship={this.state.Entrepreneurship}
                />

                <RowItem
                  title="3 ambientes"
                  key="3amb"
                  ambiences="3"
                  entrepreneurship={this.state.Entrepreneurship}
                />
              </>
              :
              null
            }
          </Col>

        </Row>
    );
  }
}

export default TablaUnidades;
