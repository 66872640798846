import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";


//Import Images
import img1 from "../../assets/images/1amb.jpg";
import img2 from "../../assets/images/2amb.jpg";
import img3 from "../../assets/images/3amb.jpg";
import { BsChevronRight, BsChevronLeft, BsChevronDown, BsChevronUp } from "react-icons/bs";

import Square from '../Square'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import TablaUnidades from '../TablaUnidades'
class UnidadesHuergo extends Component {


  constructor(props) {
    super(props);

    this.state = {


      step: 1,

    };

    this.tabHandle = this.tabHandle.bind(this);

  }

  tabHandle(step) {

    this.setState({ step: step })

  }

  render() {

    const customRenderThumb = (children) => null;
    const arrowStyles: CSSProperties = { position: 'absolute', zIndex: 2, top: 'calc(100% - 53px)', width: 53, height: 53, cursor: 'pointer', backgroundColor: 'rgb(255, 255, 255)', border: '0px' };
    const arrowStylesIzq: CSSProperties = { position: 'absolute', zIndex: 2, top: 'calc(100% - 53px)', right: '53px', width: 53, height: 53, cursor: 'pointer', backgroundColor: 'rgb(128, 224, 167)', border: '0px' };

    const imagen = () => {
      switch (this.state.step) {

        case 1: return <div className="altoImgCentro" style={{ backgroundImage: 'url(' + img1 + ')', backgroundPosition: 'center', backgroundSize: 'cover' }}></div>;
        case 2: return <div className="altoImgCentro" style={{ backgroundImage: 'url(' + img2 + ')', backgroundPosition: 'center', backgroundSize: 'cover' }}></div>;
        case 3: return <div className="altoImgCentro" style={{ backgroundImage: 'url(' + img3 + ')', backgroundPosition: 'center', backgroundSize: 'cover' }}></div>;


        default: return <h1>No match</h1>
      }
    }


    const imagenMobile = (id) => {
      switch (id) {

        case 1: return <div className="altoImgCentro" style={{ backgroundImage: 'url(' + img1 + ')', backgroundPosition: 'center', backgroundSize: 'cover' }}></div>;
        case 2: return <div className="altoImgCentro" style={{ backgroundImage: 'url(' + img2 + ')', backgroundPosition: 'center', backgroundSize: 'cover' }}></div>;
        case 3: return <div className="altoImgCentro" style={{ backgroundImage: 'url(' + img3 + ')', backgroundPosition: 'center', backgroundSize: 'cover' }}></div>;


        default: return <h1>No match</h1>
      }
    }

    return (
      <React.Fragment>

        <BrowserView >
          <section className="section" id="unidades" style={{ backgroundColor: '#ececec' }} >
            <Container >
              <h2 className="tituloSeccion">Unidades disponibles</h2>
              <br />
              <Row className="mt-4" style={{ justifyContent: 'center' }}>
                <Col lg={'10'} xs={'10'} className="mt-2 separacion tabsClickeablesunidades">
                  <div><h1 onClick={() => this.tabHandle(1)} className={this.state.step == 1 ? 'clickeableTab activeTab' : 'clickeableTab'} style={{ textAlign: 'left' }}>1 ambiente</h1></div>
                  <div><h1 onClick={() => this.tabHandle(2)} className={this.state.step == 2 ? 'clickeableTab activeTab' : 'clickeableTab'}>2 ambientes</h1></div>
                  <div><h1 onClick={() => this.tabHandle(3)} className={this.state.step == 3 ? 'clickeableTab activeTab' : 'clickeableTab'} style={{ textAlign: 'right' }}>3 ambientes</h1></div>
                </Col>
              </Row>
              <br />
              <Row>
                <Col lg="12" style={{ textAlign: 'center' }}>
                  {imagen()}
                </Col>
              </Row>

              <TablaUnidades />

            </Container>
            <div id='cajaTable'></div>
          </section>
        </BrowserView >
        <MobileView >
          <section className="section" id="unidades" style={{ backgroundColor: '#ececec' }} >

            <Container>
              <Row className="unidadesBottom">
                <Col xs="12" style={{ textAlign: 'left', padding: '0px', marginBottom: '30px' }}>
                  <h4 className="tituloUnidadesMobile">Unidades disponibles</h4>
                </Col>
              </Row>
            </Container>
            <Container className='container_footer_carr' style={{ textAlign: 'left', padding: '0px', marginBottom: '30px' }}>
              <Row>
                <Col>
                  <Carousel
                    centerMode={true}
                    infiniteLoop={true} renderThumbs={customRenderThumb} statusFormatter={(current, total) => ''} renderArrowPrev={(onClickHandler, hasPrev, label) => { if (!isMobile) { return (<button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStylesIzq }}><BsChevronLeft /></button>) } else { return (null) } }}
                    renderArrowNext={(onClickHandler, hasNext, label) => {
                      if (!isMobile) { return (<button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 0 }}><BsChevronRight /></button>) } else {
                        return (null)
                      }
                    }
                    }
                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                      return (null);
                    }}
                  >
                    <div><img alt="" src={img1} /></div>
                    <div><img alt="" src={img2} /></div>
                    <div><img alt="" src={img3} /></div>

                  </Carousel>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row>
                <Col>
                  <TablaUnidades />
                </Col>
              </Row>
            </Container>
          </section>
        </MobileView >
      </React.Fragment >
    );
  }
}

export default UnidadesHuergo;
