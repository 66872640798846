import React, { Component } from "react";
import { Container, Row, Col, FormGroup, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Animated } from "react-animated-css";

//Import Section Title
import SectionTitle from "../common/section-title";

class ContactoHuergo extends Component {

  constructor(props) {
    super(props);
    this.state = {

      name: null,
      surname: null,
      phone: null,
      email: null,
      entrepreneurshipid: 0,
      producttypeid: 0,
      productid: 0,
      message: null,


      messageResponse: null,
      msgSendSuccess: false,
      isSubmitting: false,
    };
  }

  handleSubmit = async (e) => {
    console.log(e)
    this.setState({ isSubmitting: true });
    this.setState({ messageResponse: null })

    let emailPattern = new RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/);

    if (this.state.name !== "" && this.state.surname !== "" && emailPattern.test(this.state.email) && this.state.phone !== "" && this.state.message !== "") {

      const res = await fetch(this.props.apiMailing, {
        method: "POST",
        body: JSON.stringify(this.state),
        headers: { "Content-Type": "application/json" }
      });


      //registro de evento en google
      //window.gtag("event", "formulario");

      const data = await res.json();
      this.setState({ isSubmitting: false });

      if (data.success == true) {
        this.setState({ messageResponse: data.result })
        this.setState({ msgSendSuccess: true });
        this.myFormRef.reset();
      } else {
        this.setState({ msgSendSuccess: false });
        this.setState({ messageResponse: data.message })
      }

      setTimeout(() =>
        this.setState({
          messageResponse: null,
          msgSendSuccess: false
        }),
        5000
      );

    }

    this.setState({ isSubmitting: false });


  };

  onInputChangeHandlar = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    this.setState({ [name]: value });
  };

  render() {

    //console.log("props", this.props)

    return (
      <React.Fragment>
        <section className="section " id="contacto">
          <Container>
            <h2 className="tituloSeccion tituloContactoSection">Contactate</h2>
            <Row style={{ justifyContent: 'center' }}>
              <Col md="6">
                <div className="custom-form  contactateSpace">
                  <AvForm
                    name="contact-form"
                    id="contact-form"
                    ref={(el) => (this.myFormRef = el)}
                    onSubmit={(e) => this.handleSubmit(e)}
                  >
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <AvField
                            name="name"
                            placeholder="Nombre *"
                            type="text"
                            errorMessage="Ingrese Nombre"
                            className="form-control altoForm"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={(e) => this.onInputChangeHandlar(e)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <AvField
                            name="surname"
                            placeholder="Apellido *"
                            type="text"
                            errorMessage="Ingrese Apellido"
                            className="form-control altoForm"
                            validate={{ required: { value: true } }}
                            onChange={(e) => this.onInputChangeHandlar(e)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <AvField
                            name="email"
                            placeholder="Email *"
                            type="email"
                            errorMessage="Ingrese email valido"
                            className="form-control altoForm"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={(e) => this.onInputChangeHandlar(e)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <AvField
                            name="phone"
                            placeholder="Teléfono *"
                            type="text"
                            errorMessage="Ingrese teléfono"
                            className="form-control altoForm"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={(e) => this.onInputChangeHandlar(e)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <AvField
                            type="textarea"
                            name="message"
                            id="message"
                            rows="4"
                            className="form-control "
                            errorMessage="."
                            placeholder="Mensaje ..."
                            validate={{ required: { value: false } }}
                            onChange={(e) => this.onInputChangeHandlar(e)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>



                      {this.state.msgSendSuccess ?
                        <div id="message" style={{ textAlign: 'center', width: '100%', color: '#7fe0a6' }} >
                          <Animated
                            animationIn="bounceIn"
                            animationOut="flash"
                            animationInDuration={1000}
                            animationOutDuration={1000}
                            isVisible={true}
                          >
                            <fieldset>
                              <div id="success_page">
                                <p><strong>{this.state.messageResponse}</strong></p>
                              </div>
                            </fieldset>
                          </Animated>
                        </div>
                        :
                        null
                      }



                      <Col sm="12" className="text-center">
                        <p>* Estos campos son obligatorios</p>
                        <br />

                        {!this.state.isSubmitting ?
                          <input
                            type="submit"
                            id="submit"
                            name="send"
                            className="submitBnt btn btn-primary btn-custom"
                            value="Enviar"
                          />
                          : <input
                            type="button"
                            id="submit"
                            name="send"
                            className="submitBnt btn btn-primary btn-custom"
                            value="Enviando ..."
                          />}

                        <div id="simple-msg"></div>
                      </Col>
                    </Row>
                  </AvForm>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default ContactoHuergo;
