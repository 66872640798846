import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";



import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";


class ProyectoHuergoVideo extends Component {

  constructor(props) {
    super(props);
    this.state = {



      active: 0,

      isOpen: false,
    };

    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(id) {

    this.setState({ active: id })
  }



  render() {


    const customRenderThumb = (children) => null;

    const arrowStyles: CSSProperties = {
      position: 'absolute',
      zIndex: 2,
      top: 'calc(100% - 53px)',
      width: 53,
      height: 53,
      cursor: 'pointer',
      backgroundColor: 'rgb(255, 255, 255)',
      border: '0px'

    };

    const arrowStylesIzq: CSSProperties = {
      position: 'absolute',
      zIndex: 2,
      top: 'calc(100% - 53px)',
      right: '53px',
      width: 53,
      height: 53,
      cursor: 'pointer',
      backgroundColor: 'rgb(128, 224, 167)',
      border: '0px'
    };



    return (
      <React.Fragment>
        <section className="section"  style={{ backgroundColor: 'white' }}>
          <Container >
            <Row >
              <Col lg={'12'} xs={'12'} >
                
                <img src='./assets/images/new_video_2.jpg' style={{ width: '100%' }} />
                
              </Col>
            </Row>
          </Container>

        </section>
      </React.Fragment >
    );
  }
}

export default ProyectoHuergoVideo;
